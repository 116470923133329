import React from "react";
import "./style.css";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loader2 from "../../components/Loader2/Loader2";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

const LoginScreen = () => {
  const { isLoading, isAuthenticated, error, user, loginWithRedirect } =
    useAuth0();

  if (error) {
    return <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}
    >
      <p>Oops... {error.message}</p>
    </div>;
  }

  const dispatch = useDispatch();

  if (isAuthenticated) {
    dispatch({ type: "STORE_AUTH0_USER_INFOS", payload: user });
    localStorage.setItem("userAuth0Connected", JSON.stringify(user));
  }

  return (
    <div className='login_screen'>
      <div className='col_1'></div>
      <div className='col_2'>
        <img src='/sensego_logo.png' className='logo' alt='Sensego' />
        <form action=''>
          {error && <div>Oops... {error.message}</div>}

          {isLoading ? (
            <div style={{ marginBottom: 20, marginTop: 20 }}>
              {isLoading && <Loader2 />}
            </div>
          ) : isAuthenticated ? (
            <Navigate to='/campagne/ajouter-compte' replace={true} />
          ) : (
            <button onClick={loginWithRedirect} className='login_btn'>
              <FormattedMessage defaultMessage='Log in' id='screen_login' />
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default LoginScreen;
