import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import "./style.css";
import { FormattedMessage } from "react-intl";

const LeftMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { toggleLeftMenu } = useSelector((state) => state.utils);

  const listItems = [
    {
      activeImage: "/menu/bleus/campagne.png",
      image: "/menu/blancs/campagne.png",
      title: (
        <FormattedMessage
          defaultMessage="Liste des campagnes"
          id="campagne-link-left-menu_1"
        />
      ),
      navigateTo: "/campagne/liste",
      activeLink:
        location.pathname === "/campagne/liste" ||
        location.pathname === `/campagne/details/${id}` ||
        location.pathname === "/creation-campagne" ||
        location.pathname === "/creation-campagne/package-centre-interet" ||
        location.pathname === "/creation-package-ciblage-demographique" ||
        location.pathname === "/creation-carousel" ||
        location.pathname === "/priorisation-evenements" ||
        location.pathname === `/campagne/edit/${id}`
          ? true
          : false,
    },
    {
      activeImage: "/menu/bleus/dashboard.png",
      image: "/menu/blancs/dashboard.png",
      title: (
        <FormattedMessage
          defaultMessage="Stratégies Monitoring"
          id="dashboard-link-left-menu_2"
        />
      ),
      navigateTo: "/configuration-monitoring",
      activeLink:
        location.pathname === "/configuration-monitoring" ? true : false,
    },

    {
      activeImage: "/menu/bleus/ads.png",
      image: "/menu/blancs/ads.png",
      title: (
        <FormattedMessage
          defaultMessage="Package de pub"
          id="publicite-link-left-men_3"
        />
      ),
      navigateTo: "/creation-package-pub?from=list",
      activeLink:
        location.pathname === "/creation-package-pub" ||
        location.pathname === "/creation-package-pub/ajout-video" ||
        location.pathname === "/creation-package-pub/ajout-image" ||
        location.pathname === "/creation-package-pub/ajout-ressource"
          ? true
          : false,
    },
    {
      activeImage: "/menu/bleus/ciblage.png",
      image: "/menu/blancs/ciblage.png",
      title: (
        <FormattedMessage
          defaultMessage="Audiences"
          id="ciblage-link-left-menu_4"
        />
      ),
      navigateTo: "/creation-audience?from=list",
      activeLink: location.pathname === "/creation-audience" ? true : false,
    },
    {
      activeImage: "/menu/bleus/parametres.png",
      image: "/menu/blancs/parametres.png",
      title: (
        <FormattedMessage
          defaultMessage="Settings"
          id="parametres-link-left-menu_5"
        />
      ),
      navigateTo: "/campagne/comptes-actifs",
      activeLink:
        location.pathname === "/campagne/parametres" ||
        location.pathname === "/campagne/comptes-actifs" ||
        location.pathname === "/campagne/ajouter-compte"
          ? true
          : false,
    },
  ];

  const setToggleLeftMenu = () => {
    dispatch({ type: "SET_TOGGLE_LEFT_MENU" });
  };

  const {
    user: { nickname, picture },
  } = useAuth0();

  const { startSetting } = useSelector((state) => state.monitoringCampagne);

  const {
    user: { userData },
  } = useSelector((state) => state.auth);

  useEffect(() => {
    const list_items = document.querySelectorAll(".left_menu .menu .menu_item");
    list_items.forEach((item) => {
      item.addEventListener("mouseenter", () => {
        const img = item.children[0];
        const oldSrc = img.getAttribute("src");
        const newSrc = oldSrc.replace("blancs", "bleus");
        img.setAttribute("src", newSrc);
      });
      item.addEventListener("mouseleave", () => {
        if (!item.classList.contains("active")) {
          const img = item.children[0];
          const oldSrc = img.getAttribute("src");
          const newSrc = oldSrc.replace("bleus", "blancs");
          img.setAttribute("src", newSrc);
        }
      });
    });
  }, [location]);

  return (
    <div
      className="left_menu"
      style={
        toggleLeftMenu
          ? {
              width: "5%",
              padding: "0px 5px",
            }
          : { transform: "translateX(0%)" }
      }
    >
      {!toggleLeftMenu && (
        <div className="logo_seperator">
          <img src="/logo_sensego_2.png" alt="Sensego" />
          <div className="separator"></div>
        </div>
      )}

      {toggleLeftMenu && (
        <span onClick={setToggleLeftMenu} className="expand_menu">
          <i class="fas fa-chevron-right"></i>
        </span>
      )}

      <ul className="menu">
        {listItems.map((item, index) => (
          <li
            style={
              toggleLeftMenu
                ? { display: "flex", justifyContent: "center" }
                : {}
            }
            key={index}
            className={`menu_item ${item.activeLink ? "active" : ""}`}
            onClick={() => {
              if (!userData) {
                dispatch({
                  type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
                  payload:
                    "Vous n'avez pas accès ! Vous devez d'abord vous inscrire ou vous connecter.",
                });
                dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
                return navigate("/campagne/ajouter-compte");
              }
              if (location.pathname === "/branchement-monitoring-campagne") {
                if (startSetting) {
                  dispatch({
                    type: "SHOW_POPUP_AVERTISSEMENT_MONITORING_CAMPAGNE_WITH_NAVIGATING_TO_ANOTHER_SCREEN",
                    payload: `${item.navigateTo}`,
                  });
                } else {
                  navigate(`${item.navigateTo}`);
                }
              } else {
                navigate(`${item.navigateTo}`);
              }
              dispatch({ type: "CLEAR_ALL_INPUTS_CREATION_CAMPAIGN" });
              dispatch({ type: "CLEAR_CAMPAIGN_REF_VARIABLES" });
              dispatch({ type: "CLEAR_PACKAGE_GEO_DEMO" });
            }}
          >
            <img
              src={!item.activeLink ? item.image : item.activeImage}
              alt={item.title}
              style={{ maxHeight: "29px" }}
            />
            {!toggleLeftMenu && <p>{item.title}</p>}
          </li>
        ))}
        {/*<li onClick={() => navigate(` /alertes`)}>
        alertes
          </li>*/}
      </ul>

      {!toggleLeftMenu && (
        <div
          className="profile"
          onClick={() => {
            if (!userData) {
              dispatch({
                type: "SET_ALERT_GENERAL_ERROR_MESSAGE",
                payload:
                  "Vous n'avez pas accès ! Vous devez d'abord vous inscrire ou vous connecter.",
              });
              dispatch({ type: "SHOW_ALERT_GENERAL_ERROR_MESSAGE" });
              return navigate("/campagne/ajouter-compte");
            }
            navigate("/campagne/comptes-actifs");
          }}
        >
          <div className="img_title">
            {picture && <img src={picture} alt="Profile" />}
            {nickname && <p>{nickname}</p>}
          </div>
          <i className="fas fa-chevron-right"></i>
        </div>
      )}

      {!toggleLeftMenu && (
        <div onClick={setToggleLeftMenu} className="toggle_menu">
          <i
            className={`fas fa-chevron-${!toggleLeftMenu ? "left" : "right"}`}
          ></i>
        </div>
      )}
    </div>
  );
};

export default LeftMenu;
